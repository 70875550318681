import React, { Suspense, lazy } from 'react';
import Loading from '../Loading';
function LazyImport(Component) {
    const ComponentLoadable = lazy(Component);

    return props => (
        <Suspense fallback={<Loading />}>
            <ComponentLoadable {...props} />
        </Suspense>
    );
}

export default LazyImport;