import React from 'react';
import './App.css';
import 'animate.css/animate.css';
import LazyImport from './components/LazyImport';
const Tabuleiro = LazyImport(() => import('./components/Tabuleiro'));
function App() {
  return (
    <div className="App">
      <Tabuleiro />
    </div>
  );
}

export default App;
