import React from 'react';
import './index.css';

const Loading = (props) => <div className={"loading" + (props.background ? ' loading-background':'')}>
    <>
        {props.type === 'bars' && <LoadingBars></LoadingBars>}
    </>
</div>;
Loading.defaultProps = {
    type: 'bars',
    background: false
};

class LoadingBars extends React.Component {
    componentDidMount() {
        
    }
    render() {
        return (<div className="loading-bars">
            <span></span>
            <span></span>
            <span></span>
        </div>);
    }
    
}
export default Loading;